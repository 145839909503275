/* eslint-disable */
/**
 * type (
 * form_generator, base
 * )
 */


export default [
  {
    title: '',
    params: [
      {
        label: '',
        component: 'uiSelect',
        fieldClass: 'mb-10',
        componentAttrs: {
          items: data.gggg(),
          disabled: isObj(),
          placeholder: ''
        },
        property: ''
      },
      {
        label: '',
        component: 'uiInput',
        fieldClass: 'mb-10',
        componentAttrs: {
          placeholder: 'Введите номер'
        },
        property: ''
      },
      // disabled: true,
      //large: false,
      // small: false
      {
        component: 'formSectionTitle',
        fieldClass: 'mb-10',
        componentAttrs: {
          title: ''
        }
      },
      {
        label: '',
        component: 'uiTextArea',
        fieldClass: 'mb-10',
        componentAttrs: {
          placeholder: 'Введите описание',
          rows: 3
        },
        property: ''
      },
      {
        label: 'от',
        component: 'uiDatePicker',
        fieldClass: 'mb-10',
        componentAttrs: {
          name: ''
        },
        property: ''
      },
      {
        component: 'editFiles',
        fieldClass: 'mb-10',
        property: 'file_',
        componentAttrs: {
          border_top: true,
          tooltipText: '',
          proxyData: getFiles(''),
          allowMultiple: 'true',
          uploadFolder: ''
        }
      },
      {
        component: 'mapPlace',
        fieldClass: 'mb-10',
        componentAttrs: {
          center: object.geo_polygon
        },
        property: 'geo_polygon'
      },
      {
        component: 'editPhotos',
        property: 'file_',
        fieldClass: 'mb-10',
        componentAttrs: {
          border_top: true,
          title: '',
          proxyData: getFiles(''),
          allowMultiple: 'true',
          uploadFolder: ''
        }
      },
      {
        label: '',
        component: 'uiRadio',
        componentAttrs: {
          column: false,
          name: '',
          items: data.boolRadio()
        },
        fieldClass: 'mb-10',
        property: ''
      }
    ]
  }
];
